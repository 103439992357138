@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comforter&display=swap");

$font-family-inter: 'Inter';
$font-family-comforter: 'Comforter';

$color_purp: #9747FF;

$color_black: #232323;

$color_grey: rgba(255, 255, 255, 0.50);

$gradient1: var(--gr, linear-gradient(135deg, #0014FF 0%, #FF2CDF 100%));