@import "var.scss";

.footer {
    z-index: 20;
    &__body {
        display: grid;
        grid-template-columns: auto 1fr;
        text-align: end;
        padding-bottom: 26px;
    }

    &__list {
        display: flex;
        column-gap: 40px;
        li  {
            cursor: pointer;
            transition: color 0.5s, color 0.5s;
            &:hover {
                color: $color_purp;
            }
        }
    } 
    &__button{
        display: none;
    }
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1080px) {
    .footer__body {
        row-gap: 20px;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: auto;
        justify-items: center;
    }
}

@media only screen and (max-width: 665px) {
    .footer {
        &__body-list {
            display: none;
        }
        &__listmodal{
            position: relative;
            .footer__list{
                white-space: nowrap;
                border-radius: 12px;
                background: #FFF;
                box-shadow: 0px 20px 40px 0px rgba(136, 63, 195, 0.10);
                flex-direction: column;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                row-gap: 16px;
                align-items: start;
                bottom: 30px;
                padding: 26px 30px;
            }
        }
        &__button{
            display: block;
            width: 150px;
            position: relative;
            border: 1px solid transparent;
            border-radius: 150px;
            background: white;
            background-clip: padding-box;
            padding: 10px;
            &::after {
                position: absolute;
                top: -1px;
                bottom: -1px;
                left: -1px;
                right: -1px;
                background: $gradient1;
                content: "";
                z-index: -1;
                border-radius: 150px;
            }
        }
    }
}
