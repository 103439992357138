@import "../var.scss";

.main {
    position: relative;
    &__counter {
        width: 450px;
        height: 396px;
        position: fixed;
        top: 130px;
        left: -126px;
        font-size: 400px;
        font-style: normal;
        font-weight: 500;
        line-height: unset;
        letter-spacing: -50px;
        overflow: hidden;
        color: #ede2ff;
    }
    &--uptitle {
        opacity: 0;
        transform: translate(0px, -30px);
        font-family: $font-family-comforter;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background-image: $gradient1;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }
    &__side-panel {
        position: relative;
        z-index: 49;
        &-link {
            position: fixed;
            transform: rotate(-90deg);
            left: -30px;
            bottom: 100px;
            display: flex;
            column-gap: 25px;
        }
        &-text {
            position: fixed;
            background-image: $gradient1;
            background-image: $gradient1;
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            transform: rotate(-90deg);
            top: 250px;
            right: -100px;
        }
        &-scrolltext {
            position: fixed;
            transform: rotate(-90deg);
            bottom: 150px;
            right: -6px;
        }
        &-scroll {
            position: fixed;
            bottom: 50px;
            right: 0px;
        }
    }
    &__invite {
        padding: 90px 0 143px 0;
        max-width: 100vw;
        width: 100%;
        &-title {
            color: inherit;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            margin-left: 57px;
            &-1 {
                font-size: 70px;
            }
            &-2 {
                font-size: 80px;
            }
            &-3 {
                font-size: 80px;
            }
        }
        &-button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 40px 0;
            height: 48px;
            padding: 18px 42px;
            white-space: nowrap;
        }
        &-parag {
            text-align: justify;
            opacity: 0.7;
            max-width: 500px;
        }
    }
    &__cookies {
        z-index: 33;
        display: flex;
        max-width: 1200px;
        margin: 20px;
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 8px;
        background: #fff;
        padding: 12px 16px;
        column-gap: 12px;
        align-items: flex-start;
        p {
            opacity: 0.7;
            flex: 1 0 0;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16.8px;
        }
        span {
            text-decoration-line: underline;
            cursor: pointer;
        }
    }
}

.main--general--titles {
    font-size: inherit;
    height: fit-content;
    color: transparent;
    &--bg {
        background-image: linear-gradient(#232323, #232323);
        background-repeat: no-repeat;
        height: fit-content;
        width: fit-content;
        background-position: left;
        background-size: 0 95%;
    }
}

@media only screen and (max-width: 1080px) {
    .main__side-panel {
        display: none;
    }
}

@media only screen and (max-width: 665px) {
    .main--uptitle {
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

// from: { backgroundSize: '0% 95%', textColor: 'transparent', backgroundPosition: 'left' },
// to: async (next) => {
//     await next({ backgroundSize: '100% 95%', textColor: 'transparent' });
//     await next({ textColor: '#232323' });
//     await next({ backgroundSize: '0% 95%', backgroundPosition: 'right' });
//     await next({ backgroundSize: '0% 95%', backgroundPosition: 'left' });

// },

.animation__title--general{
    background-repeat: no-repeat;
    width: fit-content;
    background-position-x: left;
    background-position-y: center;
    background-image: linear-gradient(#232323,#232323);
    background-size: 0 95%;
    color: transparent;
    height: fit-content;
}

@keyframes animation__title {
    0% {
        background-size: 0% 97%;
        background-position-x: left;
        color: transparent;
    }
    30% {
        background-size: 100% 97%;
        background-position-x: left;
        color: transparent;
    }
    55% {
        color: #232323;
        background-size: 100% 97%;
        background-position-x: right;
    }
    90% {
        background-position-x: right;
        background-size: 10% 97%;
        color: #232323;
    }
    100% {
        background-position-x: right;
        background-size: 0% 97%;
        color: #232323;
    }
}

.animation__title {
    animation: animation__title 3s ease forwards;
}

.show__image--general{
    opacity: 0;
    transform: translate(0px, 20px);
}



@keyframes slide__title {
    from {
        opacity: 0;
        transform: translate(0px, -20px);
    }
    to {
        opacity: 1;
        transform: translate(0, 0px);
    }
}

.slide__title{
    animation: slide__title 1s ease forwards;
}


@keyframes anim__parag{
    from {
        opacity: 0;
        transform: translate(0px, 30px);
    }
    to {
        opacity: 1;
        transform: translate(0, 0px);
    }
}

.anim__parag{
    animation: anim__parag 2s ease forwards;
}