.main__finds {
    z-index: 3;
    position: relative;
    padding-bottom: 424px;
    margin-top: 181px;
    .find-block {
        max-width: 100vw;
        width: 100%;
    }
    .main__categories-title {
        padding-bottom: 0;
    }

    &--body {
        display: flex;
        &-1 {
            position: relative;
            flex: 1;
            max-width: 400px;
            width: 100%;
        }
        &-2 {
            width: 800px;
        }
    }
    &--images {
        position: relative;
        &--general {
            position: absolute;
            background-repeat: no-repeat;
            background-size: contain;
            transition: transform 0.5s;
        }
        &-1 {
            background-image: url("../../../public/Images/event1.png");
            width: 138px;
            height: 150px;
            top: 200px;
            left: 91%;
        }
        &-2 {
            background-image: url("../../../public/Images/event2.jpg");
            background-size: cover;
            border-radius: 14px;
            width: 102px;
            height: 111px;
            top: 330px;
            left: 47%;
        }
        &-3 {
            background-image: url("../../../public/Images/event3.png");
            width: 68px;
            height: 74px;
            top: 436px;
            left: 83%;
        }

        &-4 {
            background-image: url("../../../public/Images/burg1.png");
            width: 190px;
            height: 190px;
            top: 69px;
            left: 9%;
        }
        &-5 {
            background-image: url("../../../public/Images/burg2.png");
            width: 88px;
            height: 96px;
            top: 226px;
            left: 1%;
        }
        &-6 {
            background-image: url("../../../public/Images/burg3.png");
            width: 163px;
            height: 45px;
            top: 270px;
            left: 14%;
        }
        &-7 {
            background-image: url("../../../public/Images/burg4.png");
            width: 156px;
            height: 169px;
            top: 329px;
            left: 5%;
        }
        &-8 {
            background-image: url("../../../public/Images/burg5.png");
            width: 136px;
            height: 38px;
            top: 348px;
            left: 19%;
        }
    }
    &--fixed {
        position: absolute;
        width: 100%;
        height: 360px;
        top: 181px;
        left: 65%;
        &-body {
            position: relative;
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////
@media only screen and (max-width: 1080px) {
    .main__finds {
        margin-top: 91px;
        padding-bottom: 334px;

        .main__categories-parag {
            padding: 24px 0 0 0;
        }

        &--body-2 {
            max-width: 450px;
            width: 100%;
        }
        &--images {
            position: relative;
            &--general {
                position: absolute;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &-1 {
                top: 278px;
                left: 64%;
            }
            &-2 {
                top: 351px;
                left: 14%;
            }
            &-3 {
                width: 42px;
                height: 46px;
                top: 436px;
                left: 56%;
            }

            &-4 {
                top: 69px;
                left: 2%;
            }
            &-5 {
                top: 226px;
                left: -17%;
            }
            &-6 {
                top: 270px;
                left: 22%;
            }
            &-7 {
                top: 329px;
                left: -7%;
            }
            &-8 {
                top: 348px;
                left: 32%;
            }
        }
        &--fixed {
            max-width: 420px;
            width: 100%;
            left: 60%;
            top: 90px;
            &-body {
                width: 100%;
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////
@media only screen and (max-width: 665px) {
    .main__finds {
        margin-top: 70px;
        padding-bottom: 427px;
        .main__categories-parag {
            max-width: 100vw;
            width: 100%;
        }

        &--images {
            &-1 {
                display: none;
            }
            &-2 {
                width: 63px;
                height: 68px;
                top: 251px;
                left: 0%;
            }
            &-3 {
                width: 42px;
                height: 46px;
                top: 299px;
                left: 20%;
            }

            &-4 {
                width: 164px;
                height: 164px;
                top: 208px;
                left: -17%;
            }
            &-5 {
                width: 76px;
                height: 83px;
                top: 340px;
                left: -51%;
            }
            &-6 {
                width: 140px;
                height: 39px;
                top: 373px;
                left: -29%;
            }
            &-7 {
                width: 134px;
                height: 146px;
                top: 420px;
                left: -55%;
            }
            &-8 {
                width: 117px;
                height: 38px;
                top: 433px;
                left: -20%;
            }
        }
        &--body-2 {
            width: 0;
        }
    }
}

@keyframes rotateToZero-1 {
    from {
        transform: rotate(33deg) translateX(60px);
    }
    to {
        transform: rotate(0deg) translateX(0);
    }
}
.rotateToZero-1{
    animation: rotateToZero-1 4s ease forwards;
}


@keyframes rotateToZero-2 {
    from {
        transform: rotate(-30deg) translateX(100px);
    }
    to {
        transform: rotate(0deg) translateX(0);
    }
}

.rotateToZero-2 {
    animation: rotateToZero-2 6s ease forwards;
}

@keyframes rotateToZero-3 {
    from {
        transform: rotate(-23deg) translateX(100px);
    }
    to {
        transform: rotate(0deg) translateX(0);
    }
}

.rotateToZero-3 {
    animation: rotateToZero-2 5s ease forwards;
}

@keyframes rotateToZero-4 {
    from {
        transform: rotate(23deg) translateX(-100px);
    }
    to {
        transform: rotate(0deg ) translateX(0);
    }
}
.rotateToZero-4 {
    animation: rotateToZero-4 6s ease forwards;
}


@keyframes rotateToZero-5 {
    from {
        transform: rotate(-20deg) translateX(-100px);
    }
    to {
        transform: rotate(0deg) translateX(0);
    }
}

.rotateToZero-5 {
    animation: rotateToZero-5 4s ease forwards;
}

@keyframes rotateToZero-6 {
    from {
        transform: rotate(13deg) translateX(-100px);
    }
    to {
        transform: rotate(0deg) translateX(0px);
    }
}

.rotateToZero-6 {
    animation: rotateToZero-6 4.5s ease forwards;
}

@keyframes rotateToZero-7 {
    from {
        transform: rotate(33deg) translateX(-100px);
    }
    to {
        transform: rotate(0deg) translateX(0);
    }
}

.rotateToZero-7 {
    animation: rotateToZero-7 5s ease forwards;
}


@keyframes rotateToZero-8 {
    from {
        transform: rotate(14deg) translateX(-100px);
    }
    to {
        transform: rotate(0deg) translateX(0px);
    }
}

.rotateToZero-8 {
    animation: rotateToZero-8 4s ease forwards;
}

