@import "../var.scss";

@keyframes rotateplus {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotateminus {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.circle {
    position: fixed;
    height: 960px;
    width: 960px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    &--body{
        position: relative;
    }
}

#circle1,
#circle3 {
    animation: rotateplus 150s ease infinite;
    transform-origin: 50% 50%; /* Set the transform origin to the center of the circles */
}

#circle2 {
    animation: rotateminus 150s ease infinite;
    transform-origin: 50% 50%; /* Set the transform origin to the center of the circles */
}

.circle4 {
    opacity: 0;
}

@media only screen and (max-width: 1080px) {
    .circle{
        position: absolute;
        width: 759px;
        height: 759px;
        top: 230px;
        left: initial;
        right: -650px;
        &>div{
            margin-left: 0 !important;
            width: 759px;
            height: 759px;
        }
        .poitns{
            width: 759px;
            height: 759px;
        }
        svg{
            width: 759px;
            height: 759px;
        }
    }
}

@media only screen and (max-width: 665px) {
    .circle{
        position: absolute;
        width: 508px;
        height: 508px;
        top: -80px;
        left: 50%;
        transform: translate(-50%,0);
        &>div{
            margin-left: 0 !important;
            width: 508px;
            height: 508px;
        }
        .poitns{
            width: 508px;
            height: 508px;
        }
        svg{
            width: 508px;
            height: 508px;
        }
    }
}

