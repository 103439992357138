@import "var.scss";

body {
    margin: 0;
    font-family: $font-family-inter, "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    color: $color_black;
    overflow-x: hidden;
    background-image: url("../../public/Images/bgapp.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

*,
*::before,
*::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

a:link,
a:visited {
    color: inherit;
    text-decoration: none;
}
textarea:focus {
    outline: none;
}
textarea {
    resize: none;
}
a:hover,
a:focus,
a:active {
    cursor: pointer;
    text-decoration: none;
}

ul,
ol {
    list-style: none;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}
button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}
input {
    all: unset; /* Сбрасываем все стили инпута */
    text-align: start;
}

.app {
    background-color: rgb(238, 232, 255);
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    background-repeat: no-repeat;
    &__content {
        padding-top: 70px;
        flex-grow: 1;
        position: relative;
    }
}

.container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0px 50px;
}

.container__wraper {
    max-width: 1360px;
    margin: 0 auto;
    padding: 0px 50px;
}

.title {
    color: #232323;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 50px;
    letter-spacing: 1.5px;
}

@media only screen and (max-width: 1280px) {
    .container {
        padding: 0 40px;
    }
    .container__wraper {
        padding: 0 40px;
    }
    .title {
        font-size: 30px;
    }
}

@media only screen and (max-width: 1080px) {
    .container {
        padding: 0 25px;
    }
    .container__wraper {
        padding: 0 25px;
    }
}

@media only screen and (max-width: 665px) {
    .container {
        padding: 0 15px;
    }
    .container__wraper {
        padding: 0 15px;
    }
    .title {
        font-size: 26px;
    }
}

.button__grad {
    color: #ffffff;
    border-radius: 40px;
    background: $gradient1;
    box-shadow: 0px 0px 40px 15px rgba(67, 37, 151, 0.15);
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        height: 150px;
        width: 50px;
        background: #ffffff53;
        left: -50px;
        top: -40px;
        transform: rotate(37deg);
        animation: moveRight 2s ease infinite;
        opacity: 0.3;
    }
    @keyframes moveRight {
        0% {
            left: -50px;
        }
        100% {
            left: 105%;
        }
    }
}

.modal--out {
    position: fixed;
    width: 100vw;
    height: 100vh;
}

.not-found {
    text-align: center;
    margin-top: 100px;
}

.not-found h1 {
    font-size: 2rem;
}

.not-found p {
    font-size: 1.2rem;
    color: #666;
}

// @media only screen and (max-width: 1280px) {}

// @media only screen and (max-width: 1080px) {}

// @media only screen and (max-width: 665px) {}
