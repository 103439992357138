.circle__loader{
    position: fixed;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 30;
}


@keyframes fill__letter__bg {
    0% {
        height: 160px;
    }
    100% {
        height: 0px;
        opacity: 1;
    }
}
.circle__loader-logo-body{
    
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.circle__loader-logo{
    width: 70px;
    overflow: hidden;
    height: 70px;
    &--rect {
        position: absolute;
        top: -10px;
        left: -10px;
        width: 180px;
        height: 0px;
        background-color: rgb(238, 232, 255);
        transform: rotate(242deg);
        overflow: hidden;
        animation: fill__letter__bg 3s linear;
    }
}

@keyframes letter__fade__out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.hidden {
    animation: letter__fade__out 1s forwards 1s;
}
.hidden--withot-animation {
    opacity: 0;
}


#circle-loader1,
#circle-loader3 {
    animation: rotateplus 150s ease infinite;
    transform-origin: 50% 50%; /* Set the transform origin to the center of the circles */
}

#circle-loader2 {
    animation: rotateminus 150s ease infinite;
    transform-origin: 50% 50%; /* Set the transform origin to the center of the circles */
}

.loader__bg{
    background-color: rgb(238, 232, 255);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;

}