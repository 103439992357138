.phone {
    position: fixed;
    top: 93px;
    right: 30%;
    z-index: 10;
    &__screen {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 4;
        width: 307px;
        height: 624px;
        background-repeat: no-repeat;
        background-image: url("../../../public/Images/phone.png");
    }
    &__screenshot{
        z-index: 3;
        position: absolute;
        top: 6px;
        left: 11px;
        height: 560px;
        max-width: 262px;
        width: 262px;
    }
    &__block {
        position: absolute;
        bottom: 0;
    }
    &-android{
        position: absolute;
        left: 0;
        top: 0;
        max-width: none;
        width: 307px;
        height: 573px;
   
        z-index: 2;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("../../../public/Images/android.png");
    }
}

.site--line {
    position: absolute;
    right: 30%;
    top: 30px;
    z-index: 4;
    width: 0;
    &-img {
        margin-left: 13px;
        background-image: url("../../../public/Images/site.png");
        background-size: cover;
        width: 256px;
        height: 1217px;
        z-index: 5;
    }
}
