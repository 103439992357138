.main__diff {
    position: relative;
    z-index: 3;
    .main__categories-title {
        padding-bottom: 0;
    }
    padding-bottom: 443px;
    &-img {
        position: relative;
        div {
            position: absolute;
        }
        &-1 {
            background-image: url("../../../public/Images/user1.png");
            width: 195px;
            height: 195px;
            top: -110px;
            left: 12%;
        }
        &-2 {
            background-image: url("../../../public/Images/user2.png");
            width: 208px;
            height: 208px;
            top: -134px;
            left: 22%;
        }
        &-3 {
            background-image: url("../../../public/Images/user3.png");
            width: 163px;
            height: 163px;
            top: 20px;
            left: 10%;
        }
        &-4 {
            background-image: url("../../../public/Images/user4.png");
            width: 241px;
            height: 241px;
            top: 105px;
            left: 16%;
        }
        &-5 {
            background-image: url("../../../public/Images/user5.png");
            width: 163px;
            height: 163px;
            top: 38px;
            left: 27%;
        }

        &-6 {
            background-image: url("../../../public/Images/user6.png");
            width: 147px;
            height: 147px;
            top: 0px;
            left: 10%;
        }
        &-7 {
            background-image: url("../../../public/Images/user7.png");
            width: 147px;
            height: 147px;
            top: 42px;
            left: 37%;
        }
    }
    &--fixed {
        position: absolute;
        width: 100%;
        height: 360px;
        top: 150px;
        left: 55%;
        &-body {
            position: relative;
        }
    }
    &--images--general {
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

@media only screen and (max-width: 1080px) {
    .main__diff {
        padding-bottom: 247px;
        .main__categories-parag {
            max-width: 300px;
            padding: 26px 0 0 0;
        }
        &--fixed {
            top: 100px;
        }
        &-img {
            width: 100vw;
            &-1 {
                top: -100px;
                left: -2%;
            }
            &-2 {
                top: -124px;
                left: 17%;
            }
            &-3 {
                top: 34px;
                left: -8%;
            }
            &-4 {
                top: 120px;
                left: 4%;
            }
            &-5 {
                top: 67px;
                left: 27%;
            }

            &-6 {
                top: -152px;
                left: 59%;
            }
            &-7 {
                top: -129px;
                left: 70%;
            }
        }
    }
}
////////////////////
@media only screen and (max-width: 665px) {
    .main__diff {
        padding-bottom: 0;
        .main__categories-parag {
            max-width: 100vw;
            padding: 26px 0 409px 0;
        }
        &--fixed {
            top: 300px;
            left: 0;
        }
        &-img {
            width: 100vw;
            &-1 {
                width: 170px;
                height: 170px;
                top: -58px;
                left: 6%;
            }
            &-2 {
                width: 181px;
                height: 181px;
                top: -77px;
                left: 47%;
            }
            &-3 {
                width: 142px;
                height: 142px;
                top: 79px;
                left: -2%;
            }
            &-4 {
                width: 168px;
                height: 168px;
                top: 168px;
                left: 25%;
            }
            &-5 {
                width: 170px;
                height: 170px;
                top: 91px;
                    left: 61%;
            }

            &-6 {
                width: 128px;
                height: 128px;
                top: -329px;
                left: 19%;
            }
            &-7 {
                width: 160px;
                height: 160px;
                top: -311px;
                left: 34%;
            }
        }
    }
}


@keyframes transforToZero-1 {
    from {
        transform: translate(-40%, 70% );
    }
    to {
        transform: translate(0, 0% );
    }
}
.transforToZero-1 {
    animation: transforToZero-1 4s ease forwards;
}


@keyframes transforToZero-2 {
    from {
        transform: translate(-50%, 60% );
    }
    to {
        transform: translate(0, 0% );
    }
}

.transforToZero-2 {
    animation: transforToZero-2 4s ease forwards;
}

@keyframes transforToZero-3 {
    from {
        transform: translate(-50%, 50% );
    }
    to {
        transform: translate(0, 0% );
    }
}

.transforToZero-3 {
    animation: transforToZero-3 4s ease forwards;
}

@keyframes transforToZero-4 {
    from {
        transform: translate(-80%, 50% );
    }
    to {
        transform: translate(0, 0% );
    }
}

.transforToZero-4 {
    animation: transforToZero-4 4s ease forwards;
}


@keyframes transforToZero-5 {
    from {
        transform: translate(-80%, 30% );
    }
    to {
        transform: translate(0%, 0% );
    }
}

.transforToZero-5 {
    animation: transforToZero-5 4s ease forwards;
}

@keyframes transforToZero-6 {
    from {
        transform: translate(80%, 30% );
    }
    to {
        transform: translate(0%, 0% );
    }
}

.transforToZero-6 {
    animation: transforToZero-6 4s ease forwards;
}

@keyframes transforToZero-7 {
    from {
        transform: translate(60%, 20% );
    }
    to {
        transform: translate(0%, 0% );
    }
}

.transforToZero-7 {
    animation: transforToZero-7 4s ease forwards;
}