@keyframes fill__letter__bg {
    0% {
        height: 160px;
    }
    100% {
        height: 0px;
        opacity: 1;
    }
}

.letter {
    width: 70px;
    overflow: hidden;
    height: 70px;
    &--rect {
        position: absolute;
        top: -10px;
        left: -10px;
        width: 180px;
        height: 0px;
        background-color: rgb(238, 232, 255);
        transform: rotate(242deg);
        overflow: hidden;
        animation: fill__letter__bg 3s linear;
    }
}

