@import "../var.scss";

@media only screen and (max-width: 1080px) {
    .main {
        &__invite {
            font-style: normal;
            font-weight: 700;
            line-height: 55px;
            padding: 90px 0;
            &-title {
                margin-left: 0px;
                &-1 {
                    font-size: 40px;
                }
                &-2 {
                    font-size: 50px;
                }
                &-3 {
                    font-size: 60px;
                }
            }
            &-button {
                margin: 28px 0 0 0;
            }
            &-parag {
                padding-top: 110px;
                max-width: 100vw;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.7px;
            }
        }
        &__categories {
            &-title {
                padding-left: 0 !important;

                &-text {
                    line-height: 45px;
                    letter-spacing: 2px;
                    width: fit-content;
                    &-1 {
                        font-size: 30px;
                    }
                    &-2 {
                        font-size: 45px;
                    }
                }
            }
        }
    }

    .main__tp__circle {
        position: relative;
        width: 100%;
        z-index: 3;
    }

    .main__tp__phone {
        position: absolute;
        top: 17px;
        right: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../../../public/Images/phone.png");
        width: 206px;
        height: 420px;
        z-index: 6;
        overflow: hidden;
        padding: 8px;
        &-site {
            border-radius: 30px;
            z-index: 4;
            background-image: url("../../../public/Images/site.png");
            top: 20px;
            right: 28px;
            height: 411px;
            width: 190px;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
        }
    }
    .main__tp_footer {
        margin-top: 16px;
        position: relative;
        border-radius: 30px;
        background: $gradient1;
        height: 250px;
        max-width: 100vw;
        width: 100%;
        margin-bottom: 74px;
        padding: 38px 60px;
       
        color: var(--fff, #fff);
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px; /* 192.308% */
        letter-spacing: 1.3px;
        &-body{
            display: flex;
            flex-direction: column;
            row-gap: 6px;
        }
        &-phone {
            position: absolute;
            top: -80px;
            right: 42px;
            background-image: url("../../../public/Images/groupphone.png");
            width: 254px;
            background-repeat: no-repeat;
            background-size: contain;
            height: 360px;
        }
    }
}

@media only screen and (max-width: 665px) {
    .main {
        &__invite {
            padding-top: 400px;
            padding-bottom: 70px;
            &-button {
                margin: 24px 0 0 0;
            }
            &-title {
                font-style: normal;
                font-weight: 700;
                line-height: 44px;
                margin-left: 0px;
                &-1 {
                    font-size: 32px;
                }
                &-2 {
                    font-size: 42px;
                }
                &-3 {
                    font-size: 52px;
                }
            }
            &-parag {
                padding-top: 32px;
            }
        }
    }

    .main__tp__phone {
        width: 138px;
        height: 281px;
        top: 30px;
        left: 50%;
        transform: translate(-50%, 0);
        &-site {
            width: 136px;
            height: 273px;
            top: 34px;
            border-radius: 30px;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    .main__tp_footer {
        display: flex;
        justify-content: center;
        margin-top: 230px;
        margin-bottom: 40px;
        &-phone {
            position: absolute;
            top: -225px;
            left: 50%;
            transform: translate(-50%,0);
            width: 176.543px;
            height: 252px;
        }
    }
}
